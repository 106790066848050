@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@layer base {
  :root {
    /* Colors from Visma Enterprise's branding guidelines
    -------------------------------------------------------------------------------------
    Variable                   | RGB channels   | Priority    | Name          | HEX
    -------------------------------------------------------------------------------------  */
    --color-ve_sunset_orange:    247  84  71;  /* Primary       Sunset Orange   (#F75447)  */
    --color-ve_red_ribbon:       240  18  69;  /* Primary       Red Ribbon      (#F01245)  */
    --color-ve_prussian_blue:      0  50  83;  /* Primary       Prussian Blue   (#003253)  */
    --color-ve_cyan:               2 209 228;  /* Primary       cyan            (#02D1E4)  */
    --color-ve_pearl_bush:       240 233 228;  /* Primary       Pearl Bush      (#F0E9E4)  */
    --color-ve_lochmara:           0 122 202;  /* Secondary     Lochmara        (#007ACA)  */
    --color-ve_golden_fizz:      232 252  37;  /* Secondary     Golden Fizz     (#E8FC25)  */
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  @apply table-auto;
}

figure.image-style-side {
  @apply float-right mt-auto ml-[2em];
}
/* Limit image width if size isn't specifically set in editor */
figure.image-style-side:not(.image_resized) {
  @apply max-w-[50%];
}

.image-style-side img {
  @apply rounded outline outline-1 -outline-offset-1 outline-gray-300/50 p-[1px];
}